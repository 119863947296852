<template>
  <v-container id="services" class="section">
    <v-row justify="center">
      <v-col align="center">
        <div>
          <v-icon
            color="#9a01cd"
            :class="$vuetify.breakpoint.mdAndUp ? 'display-4' : 'display-2'"
            style="text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5)"
          >
            mdi-cog
          </v-icon>
        </div>
        <div
          class="services"
          style="color: #9a01cd"
          :class="$vuetify.breakpoint.mdAndUp ? 'display-3' : 'display-1'"
        >
          {{ headerText[language] }}
        </div>
        <div style="width: 4rem;" class="mt-3 mb-2">
          <hr style="border: 5px solid #9a01cd" />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        xs="12"
        sm="12"
        md="6"
        lg="4"
        xl="3"
        v-for="service in services.list"
        :key="service.name.en"
      >
        <service-card
          :service="service"
          :shownService="services.shown"
          :language="language"
          @showService="showService"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ServiceCard from "@/components/ServiceCard.vue";

export default {
  name: "Services",
  props: {
    language: {
      type: String,
      required: true
    }
  },
  components: {
    ServiceCard
  },
  data: () => ({
    headerText: {
      en: "OUR SERVICES",
      es: "NUESTROS SERVICIOS"
    },
    services: {
      shown: "",
      list: [
        {
          name: {
            en: "Luminous Ads",
            es: "Anuncios Luminosos"
          },
          img: "ANUNCIOS_LUMINOSOS.jpg"
        },
        {
          name: {
            en: "Canopies",
            es: "Marquesinas"
          },
          img: "MARQUESINAS.jpg"
        },
        {
          name: {
            en: "LEDs",
            es: "LEDs"
          },
          img: "LEDS.jpg"
        },
        {
          name: {
            en: "Channel Letters",
            es: "Letras Individuales"
          },
          img: "CHANNEL_LETTERS.jpg"
        },
        {
          name: {
            en: "Signs",
            es: "Letreros"
          },
          img: "CABALLETE.jpg"
        },
        {
          name: {
            en: "Shades",
            es: "Sombras"
          },
          img: "sombras.png"
        },
        {
          name: {
            en: "Lettering",
            es: "Rotulación"
          },
          img: "ROTULACION_DE_LETREROS.jpg"
        },
        {
          name: {
            en: "Backstage?",
            es: "Bastidores"
          },
          img: "BASTIDOR.jpg"
        },
        {
          name: {
            en: "Signaling",
            es: "Señalamiento"
          },
          img: "SEÑALETICA.jpg"
        },
        {
          name: {
            en: "Awnings",
            es: "Toldos"
          },
          img: "TOLDOS.jpg"
        },
        {
          name: {
            en: "Facades",
            es: "Fachadas de Di Bond"
          },
          img: "ALUCOBOND.jpg"
        },
        {
          name: {
            en: "Billboard?",
            es: "Espectaculares"
          },
          img: "ESPECTACULARES.jpg"
        },
        {
          name: {
            en: "Tarps",
            es: "Lonas"
          },
          img: "LONAS.jpg"
        },
        {
          name: {
            en: "Tents",
            es: "Carpas"
          },
          img: "CARPA.jpg"
        },
        {
          name: {
            en: "Digital Printing",
            es: "Impresión Digital"
          },
          img: "IMPRESION_DIGITAL.jpg"
        },
        {
          name: {
            en: "Sign Posts",
            es: "Señalización"
          },
          img: "SEÑALETICA.jpg"
        },
        {
          name: {
            en: "Vehicle Wraps",
            es: "Rotulación Vehicular"
          },
          img: "ROTULACION_VEHICULAR.jpg"
        },
        {
          name: {
            en: "Graphic Floor Vinyl",
            es: "Graphic Floor Vinyl"
          },
          img: "FLOORGRAPHIC.jpg"
        },
        {
          name: {
            en: "Vinyl Cuts",
            es: "Corte de vinyl"
          },
          img: "CORTE_Y_ROTULACION_DE_VINIL.jpg"
        },
        {
          name: {
            en: "X Banner",
            es: "X Banner"
          },
          img: "XBANNER.jpg"
        },
        {
          name: {
            en: "Micro Perforated",
            es: "Micro Perforado"
          },
          img: "MICROPERFORADOS.jpg"
        },
        {
          name: {
            en: "Roll Up",
            es: "Roll Up"
          },
          img: "ROLL_UP.jpg"
        },
        {
          name: {
            en: "Vinyl",
            es: "Vinyl"
          },
          img: "VINIL_IMPRESO.jpg"
        },
        {
          name: {
            en: "Sales Module",
            es: "Módulo de venta"
          },
          img: "STAND_MESA_CON_COPETE.jpg"
        }
      ]
    }
  }),
  methods: {
    showService(name) {
      if (this.services.shown === name) return (this.services.shown = "");
      this.services.shown = name;
    }
  }
};
</script>

<style scoped>
.services {
  font-family: "Baloo 2", cursive !important;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}
</style>
