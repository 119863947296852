<template>
  <v-sheet color="grey darken-2" class="white--text section" id="contact">
    <v-container fluid>
      <v-row justify="center">
        <v-col align="center">
          <div>
            <v-icon
              color="white"
              :class="$vuetify.breakpoint.mdAndUp ? 'display-4' : 'display-2'"
              style="text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);"
            >
              mdi-card-account-phone
            </v-icon>
          </div>
          <div
            class="headerText"
            :class="$vuetify.breakpoint.mdAndUp ? 'display-3' : 'display-1'"
          >
            {{ headerText[language] }}
          </div>
          <div style="width: 4rem;" class="mt-3 mb-2">
            <hr style="border: 5px solid white" />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col xs="12" lg="6">
          <contactForm :language="language" />
        </v-col>
        <v-col xs="12" lg="6">
          <contactMap :language="language" />
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import ContactForm from "@/components/ContactForm.vue";
import ContactMap from "@/components/ContactMap.vue";

export default {
  name: "Contact",
  components: {
    ContactForm,
    ContactMap
  },
  props: {
    language: {
      type: String,
      required: true
    }
  },
  data: () => ({
    headerText: {
      en: "Contact Us",
      es: "Nosotros"
    }
  })
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@800&display=swap");

.headerText {
  font-family: "Baloo 2", cursive !important;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  color: white;
}
</style>
