<template>
  <v-container id="projects" class="section">
    <v-row justify="center">
      <v-col align="center">
        <div>
          <v-icon
            color="#9a01cd"
            :class="$vuetify.breakpoint.mdAndUp ? 'display-3' : 'display-2'"
            style="text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);"
          >
            mdi-pencil
          </v-icon>
        </div>
        <div
          class="headerText"
          :class="$vuetify.breakpoint.mdAndUp ? 'display-2' : 'display-1'"
        >
          {{ headerText[language] }}
        </div>
        <div style="width: 4rem;" class="mt-3 mb-2">
          <hr style="border: 5px solid #9a01cd" />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-carousel
          v-model="projectsCarousel"
          height="70vh"
          style="width: 100%"
          :hide-delimiters="$vuetify.breakpoint.smAndDown"
        >
          <v-carousel-item v-for="img in projectImgs" :key="img">
            <v-img
              :src="require(`@/assets/projects/${img}`)"
              contain
              width="100%"
              height="100%"
            />
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Projects",
  props: {
    language: {
      type: String,
      required: true
    }
  },
  data: () => ({
    headerText: {
      en: "OUR LATEST PROJECTS",
      es: "NUESTROS ÚLTIMOS PROYECTOS"
    },
    projectsCarousel: 0,
    projectImgs: [
      "anuncio.jpg",
      "anunio1.jpg",
      "bandera.jpg",
      "bastidor.jpg",
      "bordado.jpg",
      "calimax.jpg",
      "calimx.jpg",
      "camiseta.jpeg",
      "chargers.jpeg",
      "coroplast.jpg",
      "cubreboca gamesa.jpg",
      "cubrebocas.jpg",
      "dentista.jpg",
      "folleteria.jpeg",
      "gorra tarro.jpg",
      "marquesina.jpg",
      "micro.jpg",
      "neri.jpg",
      "plumas.jpeg",
      "totem.jpg"
    ]
  })
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@800&display=swap");

.headerText {
  font-family: "Baloo 2", cursive !important;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  color: #9a01cd;
}
</style>
