<template>
  <v-card
    :height="cardHeight"
    class="overflow-hidden serviceCard"
    @click="$emit('showService', service.name.en)"
  >
    <v-img :src="require(`@/assets/services/${service.img}`)">
      <div
        class="white--text d-flex justify-space-between"
        style="background-color: black;"
        :class="$vuetify.breakpoint.mdAndUp ? 'display-1' : 'title'"
      >
        <span class="ml-2">{{ service.name[language] }}</span>
        <span v-if="service.name.en === shownService">
          <v-icon color="white" x-large>mdi-chevron-up</v-icon>
        </span>
        <span v-else>
          <v-icon color="white" x-large>mdi-chevron-down</v-icon>
        </span>
      </div>
    </v-img>
  </v-card>
</template>

<script>
export default {
  name: "ServiceCard",
  props: {
    service: {
      type: Object,
      required: true
    },
    shownService: {
      type: String,
      required: true
    },
    language: {
      type: String,
      required: true
    }
  },
  computed: {
    cardHeight: function() {
      if (this.service.name.en === this.shownService) return "100%";
      return "100px";
    }
  }
};
</script>

<style>
.serviceCard {
  filter: grayscale(100%);
  transition: all 0.2s ease-in-out !important;
}
.serviceCard:hover {
  filter: grayscale(0%);
}
</style>
