<template>
  <v-form
    v-model="valid"
    ref="form"
    action="https://formsubmit.co/360dc9ccaf3267836ee3f685d76a952b"
    method="POST"
  >
    <v-dialog v-model="contactDialog" width="50vw">
      <v-card>
        <v-card-title>¡Gracias!</v-card-title>
        <v-card-text
          >Gracias por contactarnos. ¡Te responderemos en breve!</v-card-text
        >
      </v-card>
    </v-dialog>
    <v-container>
      <v-row>
        <v-col :class="$vuetify.breakpoint.mdAndUp ? 'title' : 'subtitle'">
          {{ headerText[language] }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="pb-0">
          <v-text-field
            name="Nombre"
            class="font-weight-bold"
            dark
            outlined
            :label="language === 'es' ? 'Nombre' : 'First Name'"
            v-model="form.firstName"
            :rules="[
              v =>
                !!v ||
                (language === 'es'
                  ? 'Se requiere el primer nombre'
                  : 'First name is required')
            ]"
            color="white"
          />
        </v-col>
        <v-col cols="12" md="6" class="pb-0">
          <v-text-field
            name="Apellidos"
            class="font-weight-bold"
            dark
            outlined
            :label="language === 'es' ? 'Apellidos' : 'Last Name'"
            v-model="form.lastName"
            :rules="[
              v =>
                !!v ||
                (language === 'es'
                  ? 'Se requiere apellido'
                  : 'Last name is required')
            ]"
            color="white"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="pb-0">
          <v-text-field
            name="email"
            class="font-weight-bold"
            dark
            outlined
            :label="language === 'es' ? 'Correo electrónico' : 'Your Email'"
            v-model="form.email"
            :rules="[
              v =>
                !!v ||
                (language === 'es'
                  ? 'Correo electronico es requerido'
                  : 'Email is required'),
              v =>
                /.+@.+\..+/.test(v) ||
                (language === 'es'
                  ? 'El email debe ser válido'
                  : 'E-mail must be valid')
            ]"
            color="white"
          />
        </v-col>
        <v-col cols="12" md="6" class="pb-0">
          <v-text-field
            name="teléfono"
            class="font-weight-bold"
            dark
            outlined
            :label="
              language === 'es' ? 'Número de teléfono' : 'Your Phone Number'
            "
            v-model="form.phone"
            :rules="[
              v =>
                !!v ||
                (language === 'es'
                  ? 'Se requiere el número de teléfono'
                  : 'Phone number is required')
            ]"
            color="white"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-text-field
            name="Asunto"
            class="font-weight-bold"
            dark
            outlined
            :label="language === 'es' ? 'Asunto' : 'Subject'"
            v-model="form.subject"
            :rules="[
              v =>
                !!v ||
                (language === 'es'
                  ? 'Asunto es obligatorio'
                  : 'Subject is required')
            ]"
            color="white"
          />
        </v-col>
      </v-row>
      <v-row style="height: 190px;">
        <v-col>
          <v-textarea
            name="Mensaje"
            class="font-weight-bold"
            dark
            outlined
            :label="language === 'es' ? 'Mensaje' : 'Message'"
            v-model="form.message"
            :rules="[
              v =>
                !!v ||
                (language === 'es'
                  ? 'Se requiere mensaje'
                  : 'Message is required')
            ]"
            color="white"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-end">
          <v-btn
            dark
            :outlined="!valid"
            :color="valid ? 'purple accent-3' : 'white'"
            x-large
            class="white--text"
            @click="submit"
          >
            {{ buttonText[language] }}
          </v-btn>
        </v-col>
      </v-row>
      <input type="hidden" name="_next" value="#contact" />
      <input
        type="hidden"
        name="_subject"
        value="Envío de nuevo formulario de contacto"
      />
      <input type="hidden" name="_captcha" value="false" />
      <input type="hidden" name="_template" value="table" />
      <input type="text" name="_honey" style="display:none" />
    </v-container>
  </v-form>
</template>

<script>
import * as FormData from "form-data";

export default {
  name: "ContactForm",
  props: {
    language: {
      type: String,
      required: true
    }
  },
  data: () => ({
    valid: false,
    contactDialog: false,
    headerText: {
      en: `Call or submit our online form to request an estimate or
                      for general inquireies. We look forward to serving you!`,
      es: `Llame o envíe nuestro formulario en línea para solicitar
                      un presupuesto o para consultas generales. ¡Esperamos
                      poder servirle!`
    },
    buttonText: {
      en: "Send Message",
      es: "Enviar Mensaje"
    },
    form: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      subject: "",
      message: ""
    }
  }),
  methods: {
    async submit() {
      console.log("submit");
      // this.$refs.form.$el.submit();
      const form = new FormData();
      form.append("Nombre", this.form.firstName);
      form.append("Apellidos", this.form.lastName);
      form.append("email", this.form.email);
      form.append("Teléfono", this.form.phone);
      form.append("Asunto", this.form.subject);
      form.append("Mensaje", this.form.message);
      form.append("_next", "http://localhost:8081/#/#contact");
      form.append("_subject", "Nuevo Contacto De Pagina");
      form.append("_captcha", "false");
      form.append("_template", "table");

      try {
        await fetch("https://formsubmit.co/ventas@olbprint.com ", {
          method: "POST",
          body: form
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.contactDialog = true;
      }
    }
  }
};
</script>

<style></style>
