<template>
  <div id="home" class="hero section">
    <div class="hero-vid">
      <div
        class="hero-vid-inner"
        :class="$vuetify.breakpoint.smAndDown ? 'fix-margin' : ''"
      >
        <video
          src="@/assets/video.mp4"
          autoplay
          muted
          loop
          playsinline
          width="100%"
        ></video>
      </div>
    </div>
    <div
      class="hero-action pa-5 d-flex align-center"
      :class="$vuetify.breakpoint.mdAndUp ? '' : 'flex-column'"
    >
      <div
        style="width: 80%"
        :class="$vuetify.breakpoint.mdAndUp ? 'title' : 'subtitle'"
      >
        {{ actionText[language] }}
      </div>
      <div>
        <v-btn
          outlined
          color="white"
          :x-large="$vuetify.breakpoint.mdAndUp"
          small
          to="/#contact"
          :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-2'"
        >
          {{ actionButton[language] }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hero",
  props: {
    language: {
      type: String,
      required: true
    }
  },
  data: () => ({
    actionText: {
      en: "Build your next experience today",
      es:
        "Calidad en impresión en gran formato, con una gama amplia de productos, acompañado de servicio, precio y calidad"
    },
    actionButton: {
      en: "Get a Quote",
      es: "Consigue una cotización"
    }
  })
};
</script>

<style>
.hero {
  background: url("../assets/herobanner.jpg");
}
.hero-vid {
  max-width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.hero-vid-inner {
  max-height: 75vh;
}
.hero-action {
  width: 100vw;
  background-color: #9a01cd;
  color: white;
}
.fix-margin {
  margin-bottom: -10px;
}
</style>
