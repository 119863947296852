<template>
  <v-sheet class="clients section" id="clients" color="grey lighten-2">
    <v-container>
      <v-row justify="center">
        <v-col align="center">
          <div>
            <v-icon
              color="#9a01cd"
              :class="$vuetify.breakpoint.mdAndUp ? 'display-4' : 'display-2'"
              style="text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5)"
            >
              mdi-account-group
            </v-icon>
          </div>
          <div
            class="clientHeader"
            style="color: #9a01cd"
            :class="$vuetify.breakpoint.mdAndUp ? 'display-3' : 'display-1'"
          >
            {{ headerText[language] }}
          </div>
          <div style="width: 4rem;" class="mt-3 mb-2">
            <hr style="border: 5px solid #9a01cd" />
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-img src="@/assets/clients.png" class="clientImg" />
  </v-sheet>
</template>

<script>
export default {
  name: "Clients",
  props: {
    language: {
      type: String,
      required: true
    }
  },
  data: () => ({
    headerText: {
      en: "OUR CLIENTS",
      es: "NUESTROS CLIENTES"
    }
  })
};
</script>

<style scoped>
.clientHeader {
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  font-family: "Baloo 2", cursive !important;
}
/* .clientImg {
  filter: grayscale(100%);
  transition: all 0.2s ease-in-out !important;
}
.clients:hover .clientImg {
  filter: grayscale(0%);
} */
</style>
