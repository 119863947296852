var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",attrs:{"action":"https://formsubmit.co/360dc9ccaf3267836ee3f685d76a952b","method":"POST"},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-dialog',{attrs:{"width":"50vw"},model:{value:(_vm.contactDialog),callback:function ($$v) {_vm.contactDialog=$$v},expression:"contactDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("¡Gracias!")]),_c('v-card-text',[_vm._v("Gracias por contactarnos. ¡Te responderemos en breve!")])],1)],1),_c('v-container',[_c('v-row',[_c('v-col',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'title' : 'subtitle'},[_vm._v(" "+_vm._s(_vm.headerText[_vm.language])+" ")])],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"font-weight-bold",attrs:{"name":"Nombre","dark":"","outlined":"","label":_vm.language === 'es' ? 'Nombre' : 'First Name',"rules":[
            function (v) { return !!v ||
              (_vm.language === 'es'
                ? 'Se requiere el primer nombre'
                : 'First name is required'); }
          ],"color":"white"},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"font-weight-bold",attrs:{"name":"Apellidos","dark":"","outlined":"","label":_vm.language === 'es' ? 'Apellidos' : 'Last Name',"rules":[
            function (v) { return !!v ||
              (_vm.language === 'es'
                ? 'Se requiere apellido'
                : 'Last name is required'); }
          ],"color":"white"},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"font-weight-bold",attrs:{"name":"email","dark":"","outlined":"","label":_vm.language === 'es' ? 'Correo electrónico' : 'Your Email',"rules":[
            function (v) { return !!v ||
              (_vm.language === 'es'
                ? 'Correo electronico es requerido'
                : 'Email is required'); },
            function (v) { return /.+@.+\..+/.test(v) ||
              (_vm.language === 'es'
                ? 'El email debe ser válido'
                : 'E-mail must be valid'); }
          ],"color":"white"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"font-weight-bold",attrs:{"name":"teléfono","dark":"","outlined":"","label":_vm.language === 'es' ? 'Número de teléfono' : 'Your Phone Number',"rules":[
            function (v) { return !!v ||
              (_vm.language === 'es'
                ? 'Se requiere el número de teléfono'
                : 'Phone number is required'); }
          ],"color":"white"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"font-weight-bold",attrs:{"name":"Asunto","dark":"","outlined":"","label":_vm.language === 'es' ? 'Asunto' : 'Subject',"rules":[
            function (v) { return !!v ||
              (_vm.language === 'es'
                ? 'Asunto es obligatorio'
                : 'Subject is required'); }
          ],"color":"white"},model:{value:(_vm.form.subject),callback:function ($$v) {_vm.$set(_vm.form, "subject", $$v)},expression:"form.subject"}})],1)],1),_c('v-row',{staticStyle:{"height":"190px"}},[_c('v-col',[_c('v-textarea',{staticClass:"font-weight-bold",attrs:{"name":"Mensaje","dark":"","outlined":"","label":_vm.language === 'es' ? 'Mensaje' : 'Message',"rules":[
            function (v) { return !!v ||
              (_vm.language === 'es'
                ? 'Se requiere mensaje'
                : 'Message is required'); }
          ],"color":"white"},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"white--text",attrs:{"dark":"","outlined":!_vm.valid,"color":_vm.valid ? 'purple accent-3' : 'white',"x-large":""},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.buttonText[_vm.language])+" ")])],1)],1),_c('input',{attrs:{"type":"hidden","name":"_next","value":"#contact"}}),_c('input',{attrs:{"type":"hidden","name":"_subject","value":"Envío de nuevo formulario de contacto"}}),_c('input',{attrs:{"type":"hidden","name":"_captcha","value":"false"}}),_c('input',{attrs:{"type":"hidden","name":"_template","value":"table"}}),_c('input',{staticStyle:{"display":"none"},attrs:{"type":"text","name":"_honey"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }