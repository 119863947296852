import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  }
];

const router = new VueRouter({
  routes,
  scrollBehavior: function(to) {
    if (to.query.ld) {
      return;
    }
    if (to.hash) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve({ selector: to.hash, offset: { y: 75 } });
        }, 100);
      });
    }
  }
});

export default router;
