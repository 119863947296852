<template>
  <v-app id="inspire" style="width: 100vw">
    <v-navigation-drawer v-model="drawer" right app color="rgba(0,0,0,0.95)">
      <div>
        <v-btn color="white" text large to="/#home" width="100%">
          <v-icon>mdi-home</v-icon>
          <span class="ml-2">
            {{ navButtonsText.home[language] || navButtonsText.home.es }}
          </span>
        </v-btn>
      </div>
      <div>
        <v-btn color="white" text large to="/#services" width="100%">
          <v-icon>mdi-cog</v-icon>
          <span class="ml-2">
            {{
              navButtonsText.services[language] || navButtonsText.services.es
            }}
          </span>
        </v-btn>
      </div>
      <div>
        <v-btn color="white" text large to="/#projects" width="100%">
          <v-icon>mdi-pencil</v-icon>
          <span class="ml-2">
            {{
              navButtonsText.projects[language] || navButtonsText.projects.es
            }}
          </span>
        </v-btn>
      </div>
      <div>
        <v-btn color="white" text large to="/#contact" width="100%">
          <v-icon>mdi-card-account-phone</v-icon>
          <span class="ml-2">
            {{ navButtonsText.contact[language] || navButtonsText.contact.es }}
          </span>
        </v-btn>
      </div>
    </v-navigation-drawer>

    <v-app-bar app flat color="rgba(0, 0, 0, 0.85)" width="100vw">
      <v-img
        src="@/assets/logo.png"
        class="shrink"
        width="50px"
        transition="scale-transition"
      />
      <v-spacer />
      <a href="tel:+526865670840">
        <v-btn color="white" text>
          <v-icon color="white" large>mdi-phone</v-icon>
          +52 686 567 0840
        </v-btn>
      </a>
      <div v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn color="white" text large to="/#home">
          {{ navButtonsText.home[language] || navButtonsText.home.es }}
        </v-btn>
        <v-btn color="white" text large to="/#services">
          {{ navButtonsText.services[language] || navButtonsText.services.es }}
        </v-btn>
        <v-btn color="white" text large to="/#projects">
          {{ navButtonsText.projects[language] || navButtonsText.projects.es }}
        </v-btn>
        <v-btn color="white" text large to="/#contact">
          {{ navButtonsText.contact[language] || navButtonsText.contact.es }}
        </v-btn>
      </div>
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        v-else
        style="color: white"
      />
    </v-app-bar>

    <v-main>
      <router-view :language="language" />
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    language: "es",
    drawer: false,
    navButtonsText: {
      home: {
        en: "Home",
        es: "Inicio"
      },
      services: {
        en: "Services",
        es: "Servicios"
      },
      projects: {
        en: "Projects",
        es: "Proyectos"
      },
      contact: {
        en: "Contact",
        es: "Contacto"
      }
    }
  })
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,400;0,700;1,700&family=Montserrat&display=swap");
* {
  font-family: "Karla", sans-serif;
}
.v-btn {
  font-weight: 700 !important;
}
</style>
