<template>
  <v-container>
    <v-row>
      <v-col xs="12" lg="6">
        <div class="display-1 headerText">
          {{ headerText.address[language] }}
        </div>
        <div>Calzada Cuauhtémoc # 1362 Fracc. Vista Hermosa</div>
        <div>Mexicali, Baja California 21240</div>
      </v-col>
      <v-col xs="12" lg="6">
        <div class="display-1 headerText">
          {{ headerText.contactInfo[language] }}
        </div>

        <div>
          <a href="tel:+526865670840">
            +52 686 567 0840
          </a>
        </div>
        <div>
          <a href="mailto:Ventas@olbprint.com">
            Ventas@olbprint.com
          </a>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <iframe
          height="400px"
          width="100%"
          frameborder="0"
          style="border:0"
          src="https://www.google.com/maps/embed/v1/place?key=AIzaSyB4i1JWspCMOr7lRXECK-ohcDj44AiCwsg
                    &q=OLB+Impresiones+Mexicali,+B.C.,+Mexico"
          allowfullscreen
        >
        </iframe>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ContactMap",
  props: {
    language: {
      type: String,
      required: true
    }
  },
  data: () => ({
    headerText: {
      address: {
        en: "Address",
        es: "Dirección"
      },
      contactInfo: {
        en: "Contact Info",
        es: "Contáctanos"
      }
    }
  })
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@800&display=swap");

a {
  color: white !important;
}

a:hover {
  color: #ea80fc !important;
}

.headerText {
  font-family: "Baloo 2", cursive !important;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}
</style>
