<template>
  <div style="width: 100vw">
    <hero :language="language" />
    <services :language="language" />
    <clients :language="language" />
    <projects :language="language" />
    <contact :language="language" />
  </div>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Services from "@/components/Services.vue";
import Clients from "@/components/Clients.vue";
import Projects from "@/components/Projects.vue";
import Contact from "@/components/Contact.vue";

export default {
  name: "Home",
  props: {
    language: {
      type: String,
      required: true
    }
  },
  components: {
    Hero,
    Services,
    Clients,
    Projects,
    Contact
  },
  methods: {
    observeSections() {
      if (this.sectionObserver) {
        this.sectionObserver.disconnect();
      }

      const options = {
        rootMargin: "0px 0px",
        threshold: 0.5
      };

      this.sectionObserver = new IntersectionObserver(
        this.sectionObserverHandler,
        options
      );

      const sections = document.querySelectorAll(".section");
      sections.forEach(section => {
        this.sectionObserver.observe(section);
      });
    },
    sectionObserverHandler(entries) {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          const sectionId = entry.target.id;
          this.$router.replace({
            name: this.$route.name,
            hash: `#${sectionId}`,
            query: { ld: true }
          });
        }
      }
    }
  },
  mounted() {
    this.observeSections();
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@800&display=swap");

.headerText {
  font-family: "Baloo 2", cursive !important;
}
</style>
